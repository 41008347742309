const JsGallery = {
	init () {
		/*
    * Lightgallery
    */

		if ($('.c-gallery').length) {
			// Lightgallery galirry...galle ..galery .. gallerellery .. um yeah .. pretty pictures
			$('.c-gallery').lightGallery({
				selector: '.c-gallery__item',
				thumbnail: true,
				showThumbByDefault: false,
				download: false
			});
		}
	}
};

export default JsGallery;
