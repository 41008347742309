// NPM dependencies
import $ from 'jquery';
import 'babel-polyfill';
import 'foundation-sites';
import 'slick-carousel';
import 'lightgallery';
import 'lg-thumbnail';
import 'lazysizes';
import pictureFill from 'picturefill';
import objectFitImages from 'object-fit-images';
import Headroom from 'headroom.js';

// Project Partials
import searchForm from '../../src/components/02-objects/form/searchForm';
import JsCard from '../../src/components/02-objects/card/card';
// import googleMaps from '../../src/components/02-objects/map/map';

import JsGallery from '../../src/components/03-ui/gallery/gallery';
import JsSiteHeader from '../../src/components/03-ui/site-header/site-header';
import JsSlider from '../../src/components/03-ui/slider/slider';
import JsSVGMap from '../../src/components/03-ui/svg-map/svg-map';

// Import mini modules
import JsInfiniteScroll from './modules/infinite-scroll';
import JsScrollTo from './modules/smooth-scroll-to';

var jQueryBridget = require('jquery-bridget');
var InfiniteScroll = require('infinite-scroll');

// make Infinite Scroll a jQuery plugin
jQueryBridget('infiniteScroll', InfiniteScroll, $);

var AOS = require('aos');

$(document).ready(() => {
	// IE 11 Foreach Polyfill
	// if ('NodeList' in window && !NodeList.prototype.forEach) {
	//   console.info('Nodelist forEach polyfill for IE11');
	//   NodeList.prototype.forEach = function (callback, thisArg) {
	//     thisArg = thisArg || window;
	//     for (var i = 0; i < this.length; i++) {
	//       callback.call(thisArg, this[i], i, this);
	//     }
	//   };
	// }

	if (window.NodeList && !NodeList.prototype.forEach) {
		NodeList.prototype.forEach = Array.prototype.forEach;
	}
	if (window.HTMLCollection && !HTMLCollection.prototype.forEach) {
		HTMLCollection.prototype.forEach = Array.prototype.forEach;
	}

	// Init foundation on doc ready
	$(document).foundation();

	// Object Fit Polyfill
	// https://github.com/bfred-it/object-fit-images
	objectFitImages();

	// Picture Polyfill
	// http://scottjehl.github.io/picturefill/
	pictureFill();

	// Initialise imported js (this would be js required for every page, globally, i.e. headers
	JsInfiniteScroll.init();
	JsScrollTo.init();
	JsCard.init();
	JsGallery.init();
	JsSlider.init();
	JsSiteHeader.init(Headroom);
	searchForm.init();
	JsSVGMap.init();

	// googleMaps.init();

	// Conditionally import and initialise additional chunks
	// See @ https://webpack.js.org/guides/code-splitting/ && https://sebastiandedeyne.com/posts/2018/code-splitting-with-laravel-mix
	// Depending on the page, these additional components may or may not be needed.
});

// Init Animate on Scroll
// https://michalsnik.github.io/aos/
AOS.init({
	startEvent: 'load'
});
