const JsScrollTo = {
	init () {
		if ($('.js-scroll').length) {
			$('.js-scroll').click(function () {
				if (location.pathname.replace(/^\//, '') === this.pathname.replace(/^\//, '') && location.hostname === this.hostname) {
					let target = $(this.hash);

					target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');

					if (target.length) {
						$('html,body').animate({
							// 120 is header offset value
							scrollTop: target.offset().top - 120
						}, 500);
						return false;
					}
				}
			});
		}
	}
};

export default JsScrollTo;
