const JsCard = {
	init () {
		// Card media toggles
		if ($('.e-button--media-toggle').length) {
			$('.e-button--media-toggle').click(function () {
				// First grab the container for all toggles
				const mediaTogglesContainer = $(this).closest('.o-card-media-toggles');

				// Loop through all toggles to remove active class
				$(mediaTogglesContainer).find('.e-button--media-toggle').each(function () {
					if ($(this).hasClass('active')) {
						$(this).removeClass('active');
					}
				});

				// Add active class for this toggle button
				$(this).addClass('active');

				// Get this card's media container
				const mediaContainer = $(this).closest('.o-card-media-wrapper');

				// Get button's toggle target
				const target = $(this).data('media-toggle');

				$(mediaContainer).find('.o-card-media').each(function () {
					if (!$(this).hasClass('hide')) {
						$(this).addClass('hide');
					}

					if ($(this).data('card-media-type') === target) {
						$(this).removeClass('hide');
					}
				});
			});
		}
	}
};

export default JsCard;
