const JsSiteHeader = {
	init (Headroom) {
		/*
     * Site Header - headroom js
     */

		if ($('.c-site-header').length) {
			var myElement = document.querySelector('header');
			// construct an instance of Headroom, passing the element
			var headroom = new Headroom(myElement, {
				offset: 150
			});
			// initialise
			headroom.init();
		}
	}

};

export default JsSiteHeader;
