const searchForm = {
	init () {
		$('#target').click(function () {
			var x = document.getElementById('drop');
			if (x.style.display === 'block') {
				x.style.display = 'none';
			} else {
				x.style.display = 'block';
			}
		});
		// close init
	}
	// close const
};

export default searchForm;
