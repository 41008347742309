const JsSlider = {
	init () {
		// Generic simple slider
		if ($('.c-slider--simple').length) {
			$('.c-slider--simple').slick({
				// Lazyload all the things
				lazyLoad: 'ondemand',
				slidesToShow: 1,
				slidesToScroll: 1,
				arrows: true,
				prevArrow: '<div class="slick-prev" aria-hidden="true"><div>',
				nextArrow: '<div class="slick-next" aria-hidden="true"><div>',
				dots: false,
				draggable: true,
				pauseOnFocus: false,
				pauseOnHover: true,
				fade: true,
				infinite: true,
				cssEase: 'linear',
				autoplay: true,
				autoplaySpeed: 3000
			});
		}

		// Related Places Slider
		if ($('.c-slider--places').length) {
			$('.c-slider--places').slick({
				// Lazyload all the things
				lazyLoad: 'ondemand',
				slidesToShow: 4,
				slidesToScroll: 4,
				arrows: true,
				prevArrow: '<div class="slick-prev" aria-hidden="true"><div>',
				nextArrow: '<div class="slick-next" aria-hidden="true"><div>',
				dots: false,
				draggable: true,
				pauseOnFocus: false,
				pauseOnHover: true,
				fade: false,
				infinite: false,
				cssEase: 'linear',
				autoplay: true,
				autoplaySpeed: 3000,
				responsive: [
					{
						breakpoint: 768,
						settings: {
							slidesToShow: 1,
							slidesToScroll: 1
						}
					}
				]
			});
		}
		// Whats on home Slider
		if ($('.c-slider--events-home').length) {
			$('.c-slider--events-home').slick({
				// Lazyload all the things
				lazyLoad: 'ondemand',
				slidesToShow: 4,
				slidesToScroll: 4,
				arrows: true,
				prevArrow: '<div class="slick-prev" aria-hidden="true"><div>',
				nextArrow: '<div class="slick-next" aria-hidden="true"><div>',
				dots: false,
				draggable: true,
				pauseOnFocus: false,
				pauseOnHover: true,
				fade: false,
				infinite: false,
				cssEase: 'linear',
				autoplay: true,
				autoplaySpeed: 3000,
				responsive: [
					{
						breakpoint: 768,
						settings: {
							slidesToShow: 2,
							slidesToScroll: 1,
							fade: false
						}
					},
					{
						breakpoint: 640,
						settings: {
							slidesToShow: 1,
							slidesToScroll: 1,
							fade: false
						}
					},
					{
						breakpoint: 460,
						settings: {
							slidesToShow: 1,
							slidesToScroll: 1,
							fade: false
						}
					}
				]
			});
		}
	}
};

export default JsSlider;
