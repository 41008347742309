const JsInfiniteScroll = {
	init () {
		function initInfiniteScroll () {
			if ($('.pagination-next a').length) {
				$('.infinite-scroll').infiniteScroll({
					path: '.pagination-next a',
					append: '.infinite-scroll-item',
					button: '.view-more-button',
					// hide normal Pagination
					hideNav: '.e-pagination-wrapper',
					// using button, disable loading on scroll
					scrollThreshold: false,
					status: '.page-load-status'
				});

				$('.page-load-status').css('display', '');
				$('.view-more-button').css('display', '');
			}
		}

		function adjustPaginationStyles () {
			if ($('.infinite-scroll').prop('infiniteScrollGUID') === '1') {
				$('.infinite-scroll').infiniteScroll('destroy');

				// console.log("IS was init");
			} else {
				// console.log("IS wasn't init");
			}

			$('.page-load-status').css('display', 'none');
			$('.view-more-button').css('display', 'none');

			if ($('.e-pagination-wrapper').css('display') === 'none') {
				$('.e-pagination-wrapper').css('display', '');
			}
		}

		function checkBP () {
			if (Foundation.MediaQuery.is('small only')) {
				initInfiniteScroll();

				console.log($('.infinite-scroll'));
			} else {
				adjustPaginationStyles();
			}
		}

		if ($('.infinite-scroll').length && $('.pagination-next a').length) {
			checkBP();

			// Listen for bp changes
			$(window).on('changed.zf.mediaquery', function (event, newSize, oldSize) {
				// newSize is the name of the now-current breakpoint, oldSize is the previous breakpoint

				// If bp changes to one we recognise as using infinite-scroll, init the infinite scroll
				checkBP();
			});
		}
	}
};

export default JsInfiniteScroll;
