const JsSVGMap = {
	init () {
		// Card media toggles
		if ($('.c-where-to-go-map').length) {
			// Show first map toggle item by default
			$('.map-toggle-item').first().removeClass('hide');

			const initialActiveSector = $('.map-toggle-item').first().attr('data-map-sector');

			$('.c-where-to-go-map-container').find('.map-sector[data-name="' + initialActiveSector + '"]').addClass('active');

			// changed on request of client 1/3/21
			// $('.map-sector').onMouseOver(function () {
			// 	const name = $(this).attr('data-name');

			$('.map-sector').click(function () {
				const name = $(this).attr('data-name');
				// let id = $(this).attr('id');

				// console.log(name);
				// console.log(id);

				// Remove active class from map sectors
				$('.c-where-to-go-map-container').find('.map-sector').each(function () {
					if ($(this).hasClass('active')) {
						$(this).removeClass('active');
					}
				});

				// Make this map sector active
				$(this).addClass('active');

				// Hide most cards
				$('.c-where-to-go-map').find('.map-toggle-item').each(function () {
					if (!$(this).hasClass('hide')) {
						$(this).addClass('hide');
					}
				});

				// Toggle display of cards
				$('.map-toggle-item[data-map-sector="' + name + '"]').removeClass('hide');
			});
		}
	}
};

export default JsSVGMap;
